@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root {
  --Primary-color: #0e1c9e;
  --Primary-white: #ffffff;
  --Primary-red: #f93a00;
  --Primary-black: #000000;
  --Primary-gray: #666666;
  --Primary-light-grey: #f4f4f4;
  --Secondary-gray: #c2c1c1;
}

body {
font-family: "Roboto Condensed", sans-serif !important;
}

/************************ NAVBAR *********************************/
nav {
  z-index: 2;
}

.logo {
  width: 16.25rem;
}

nav a.nav-link {
  font-size: .8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #0e1c9e !important;
  color: var(--Primary-color) !important;
  border-top: 0.1875rem solid #ffffff;
  border-top: 0.1875rem solid var(--Primary-white);
}

@media(max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-brand {
  max-width: 17.25rem !important;
  padding: 0.25rem !important;
  height: 6rem !important;
}

.navbar-nav > li > a.active,  .navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid #0e1c9e;
  border-top: 0.1875rem solid var(--Primary-color);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid #0e1c9e;
  border-top: 0.1875rem solid var(--Primary-color);
}

/************************ Header *********************************/
.header-wraper {
  position: relative;
  background: url(/static/media/background.e77dd0ca.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 80vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #ffffff;
  color: var(--Primary-white);
  text-transform: uppercase;
}

.typed-text {
  font-size: 2rem;
  color: #ffffff;
  color: var(--Primary-white);
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;

  }
}

.btn-main-offer {
  /* border: 1px solid var(Primary-color); */
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #ffffff;
  color: var(--Primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: #0e1c9e;
  background-color: var(--Primary-color);
  color: #ffffff;
  color: var(--Primary-white);
  transition:  .2s ease-in-out;
}

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/************************ About Me *********************************/
.photo-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img{
  width: 18rem;
  border-radius:  5%;
  padding: .3rem;
  border: 1px solid #0e1c9e;
  border: 1px solid var(--Primary-color);
}

.about-heading{
  color: #0e1c9e;
  color: var(--Primary-color);
  text-transform: uppercase;
}

@media(max-width: 468px){
  .profile-img{
    width: 10rem;
  }
}

/************************ Experience *********************************/
.experience {
  padding: 0.7rem;
  background: #ffffff;
  background: var(--Primary-white);
}

.experience-wrapper{
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1{
  text-transform: uppercase;
  color:#0e1c9e;
  color:var(--Primary-color);
}

.experience h2{
  text-transform: uppercase;
  color:#666666;
  color:var(--Primary-gray);
}

.experience-wrapper::before{
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #666666;
  background: var(--Primary-gray);
  z-index: 1;
}

.timeline-block{
  width: calc(50% + 0.5rem);
  display:flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right{
  float: right;
}

.timeline-block-left{
  float: left;
  direction: rtl;
}

.marker{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid #0e1c9e;
  border: 0.3125rem solid var(--Primary-color);
  background: #ffffff;
  background: var(--Primary-white);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content{
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--Primary-gray);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: #0e1c9e;
  color: var(--Primary-color);
  font-weight: 900;
  text-transform: uppercase;
}

@media(max-width: 768px){
.experience.container {
  padding-left: 0;
}
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;

  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;

  }
}

/************************ Portfolio *********************************/
.portfolio-wrapper{
  padding: 3rem 0;
  background: #f4f4f4;
  background: var(--Primary-light-grey);
}

.portfolio-wrapper h1{
  text-transform: uppercase;
  color:#0e1c9e;
  color:var(--Primary-color);
}

.portfolio-image{
  width: 15rem;
  height: 8rem;
  border: 1px solid #0e1c9e;
  border: 1px solid var(--Primary-color);
  padding: 0 0.5rem;
  border-radius:  5%;
}

.portfolio-image-box{
  position:relative;
  margin: 1rem;
}

.overflow{
  position:absolute;
    opacity: 0;
    background: #666666;
    background: var(--Primary-gray);
    width: 15rem;
    height: 8rem;
    top: 0;
  border: 1px solid #0e1c9e;
  border: 1px solid var(--Primary-color);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow{
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon{
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #0e1c9e;
  color: var(--Primary-color);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon{
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.portfolio-image-popupbox{
  width: 45rem;
  padding: 0 0.5;
}

.hyper-link{
  cursor: pointer;
  color: #666666;
  color: var(--Primary-gray)
}

.hyper-link:hover{
  cursor: pointer;
  background-color: #0e1c9e;
  background-color: var(--Primary-color);
  color: #ffffff;
  color: var(--Primary-white)
}

.popupbox-wrapper{
  margin-top: 7rem;
}

@media(max-width: 768px){
  .portfolio-image-popupbox{
    width: 100%;
  }
}

@media(max-height: 500px){
  .portfolio-image-popupbox{
    width: 60%;
  }
  .popupbox-wrapper{
    height: 100%;
  }
}

@media(max-height: 280px){
  .portfolio-image-popupbox{
    width: 30%;
  }
  .popupbox-wrapper{
    height: 90%;
  }
}

/************************ Contact ME *********************************/
.contacts{
  background: #f4f4f4;
  background: var(--Primary-light-grey);
  padding: 3rem 0;
}

.contacts h1{
  color: #0e1c9e;
  color: var(--Primary-color);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p{
  color: #0e1c9e;
  color: var(--Primary-color);
  font-size: 1.2rem;
}

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  background: var(--Primary-white);
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid #ffffff;
  border-bottom: 0.125rem solid var(--Primary-white);
  border-radius: 0;
  color: #0e1c9e;
  color: var(--Primary-color);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: #c2c1c1;
  background: var(--Secondary-gray);
  color: #ffffff;
  color: var(--Primary-white);
  border-bottom: 0.125rem solid #ffffff;
  border-bottom: 0.125rem solid var(--Primary-white);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
  color:#0e1c9e;
  color:var(--Primary-color)
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: #0e1c9e;
  background: var(--Primary-color);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: #ffffff;
  color: var(--Primary-white);
  background: #f93a00;
  background: var(--Primary-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: #ffffff;
  color: var(--Primary-white);
}

@media(max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }
  
  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}

/************************ Footer *********************************/
.footer {
  background: #666666;
  background: var(--Primary-gray);
  color: #ffffff;
  color: var(--Primary-white);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: #ffffff;
  color: var(--Primary-white);
}

.footer a:hover {
  text-decoration: none;
  color: #0e1c9e;
  color: var(--Primary-color);
  cursor:pointer;
}

.footer p {
  margin-bottom: 0;
  text-align: left;

}

.footer i {
  text-decoration: none;
  color: #ffffff;
  color: var(--Primary-white);
}

.pt-3 {
  margin-bottom: 0;
  text-align: center;
  color: #ffffff;
  color: var(--Primary-white);
  font-size: 1rem !important;

}

.fot-link {
  font-size: .8rem;
  text-transform: uppercase;
  /* letter-spacing: 0.0375rem; */
  cursor: pointer;
}





